<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <RolesTable></RolesTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RolesTable from "./tables/RolesTable.vue";
export default {
  name: "Rollen",
  components: {
    RolesTable,
  },
  data() {
    return {
      msg: "Rollen",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
